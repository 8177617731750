export enum AppLayoutsEnum {
  default = 'default',
  dashboard = 'dashboard',
  consultation = 'consultation',
  empty = 'empty',
}

export const AppLayoutToFileMap: Record<AppLayoutsEnum, string> = {
  default: 'AppLayoutDefault.vue',
  dashboard: 'AppLayoutDashboard.vue',
  consultation: 'AppConsultation.vue',
  empty: '',
};
