import { createRouter, createWebHistory, useRoute } from 'vue-router';
import { loadLayoutMiddleware } from '@/router/middleware/loadLayout.middleware';
import { AppLayoutsEnum } from '@/layouts/layouts.types';
import { getResearch } from '@/services/ApiRequests';
import { useResearch } from '@/stores/Research';
import { Research } from '@/domain/Research';
import { useCompareFirmStore } from '@/stores/CompareFirm';

// @ts-ignore
const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: '/',
      name: 'home',
      component: () => import('@/views/HomeView.vue'),
    },
    {
      path: '/lp',
      name: 'lp',
      component: () => import('@/views/LandingPage.vue'),
    },
    {
      path: '/:id_research/pdf',
      name: 'pdf',
      component: () => import('@/views/PDFViews.vue'),
      meta: {
        layout: AppLayoutsEnum.empty,
      },
    },
    {
      path: '/:id_research',
      name: 'research',
      component: () => '',
      beforeEnter: async function (to) {
        const researchId = to.params.id_research as string;

        try {
          const response = await getResearch(researchId);
          if (response) {
            useResearch().$patch({
              research: new Research(response.data),
            });

            if (response.data.contact_information) {
              await router.push(`/results/${researchId}/overall-score`);
            } else {
              useCompareFirmStore().$patch({
                step: 2,
              });
              useResearch().$patch({
                isAlreadyDone: true,
              });

              await router.push({ name: 'home' });
            }
          }
        } catch (err) {
          await router.push({ name: 'not-found' });
        }
      },
    },
    {
      path: '/results/:id_research/overall-score',
      name: 'overall-score',
      component: () => import('@/views/OverallScore.vue'),
      meta: {
        layout: AppLayoutsEnum.dashboard,
      },
    },
    {
      path: '/results/:id_research/web-presence',
      name: 'web-presence',
      component: () => import('@/views/WebPresence.vue'),
      meta: {
        layout: AppLayoutsEnum.dashboard,
      },
    },
    {
      path: '/results/:id_research/firm-reputation',
      name: 'firm-reputation',
      component: () => import('@/views/FirmReputation.vue'),
      meta: {
        layout: AppLayoutsEnum.dashboard,
      },
    },
    {
      path: '/results/:id_research/website-performance',
      name: 'website-performance',
      component: () => import('@/views/WebsitePerformance.vue'),
      meta: {
        layout: AppLayoutsEnum.dashboard,
      },
    },
    {
      path: '/results/:id_research/website-authority',
      name: 'website-authority',
      component: () => import('@/views/WebsiteAuthority.vue'),
      meta: {
        layout: AppLayoutsEnum.dashboard,
      },
      beforeEnter: async function (to) {
        const researchId = to.params.id_research as string;

        if (!useResearch().research) {
          try {
            const response = await getResearch(researchId);

            if (response) {
              useResearch().$patch({
                research: new Research(response.data),
              });

              if (!useResearch().research?.isAuthority()) {
                await router.push({ name: 'not-found' });
              }
            }
          } catch (err) {
            await router.push({ name: 'not-found' });
          }
        }
      },
    },
    {
      path: '/request-consultation',
      name: 'request-consultation',
      component: () => import('@/views/RequestConsultation.vue'),
      meta: {
        layout: AppLayoutsEnum.consultation,
      },
    },
    {
      path: '/:id_research/:subpath(.*)',
      redirect: (to) => {
        return `/results/${to.params.id_research}/${to.params.subpath}`;
      },
    },
    {
      path: '/results/:id_research',
      redirect: (to) => {
        return `/results/${to.params.id_research}/overall-score`;
      },
    },
    {
      path: '/:pathMatch(.*)*',
      name: 'not-found',
      component: () => import('@/views/NotFoundView.vue'),
    },
  ],
  scrollBehavior() {
    return { top: 0 };
  },
});

router.beforeEach(loadLayoutMiddleware);

router.onError((error, to) => {
  if (
    error.message.includes('Failed to fetch dynamically imported module') ||
    error.message.includes('Importing a module script failed')
  ) {
    if (!to?.fullPath) {
      window.location.reload();
    } else {
      // @ts-ignore
      window.location = to.fullPath;
    }
  }
});

export default router;
