<script setup lang="ts">
import { useRoute } from 'vue-router';
const route = useRoute();
</script>

<template>
  <div v-if="route.meta.layout === 'empty'">
    <slot />
  </div>
  <component v-else :is="route.meta.layoutComponent">
    <slot />
  </component>
</template>

<style scoped lang="scss">
.app-layout {
  display: flex;
  flex-direction: column;
  min-height: 100vh;

  ::v-deep(.main) {
    flex: 1 0 auto;
  }
}
</style>
